import React from 'react'
import { Tooltip } from 'react-tooltip'

function Info(props) {
    return (
        <>
        <a id={props.id}><svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft:"1vw"}} width="1.3vw" height="1.3vw" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg></a>
        <Tooltip anchorSelect={`#${props.id}`} place="right" clickable>
              <div style={{fontSize:"0.9vw"}}>
                <div>{props.infos}</div>
              <a target="_blank" href={props.link}>Read more</a></div>
        </Tooltip>
        </>
      );
}

export default Info;
